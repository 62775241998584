import { loadStripe } from "@stripe/stripe-js";


async function handleCheckout(cart) {
    const stripeProd = process.env.REACT_APP_STRIPE_PROD;
    const stripeTest = process.env.REACT_APP_STRIPE_TEST;
    let email = JSON.parse(localStorage.getItem('email'));
 


        const stripe = await loadStripe(stripeProd) // PROD
        // const stripe = await loadStripe(stripeTest) // TEST
       
        const { error } = await stripe.redirectToCheckout({
           

     
            lineItems: cart.map(({ price, quantity }) => ({
                price,
                quantity,
              })),
            mode: 'payment',
            customerEmail: email[email.length-1],
            // client_reference_id: 'test', 

            // successUrl: "http://localhost:3000/confirmation",// TEST
            // cancelUrl: "http://localhost:3000/book"// TEST


            successUrl: "https://stephspetretreat.com/confirmation", // PROD
            cancelUrl: "https://stephspetretreat.com/book" // PROD
        })
    }

export default handleCheckout